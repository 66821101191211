import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { showSuccessToast, showFailToast } from 'vant';
export default {
  __name: 'index',
  setup(__props) {
    showSuccessToast('成功文案');
    showFailToast('失败文案');
    return (_ctx, _cache) => {
      const _component_van_card = _resolveComponent("van-card");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_swipe_cell = _resolveComponent("van-swipe-cell");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_swipe_cell, null, {
        right: _withCtx(() => [_createVNode(_component_van_button, {
          square: "",
          text: "删除",
          type: "danger",
          class: "delete-button"
        })]),
        default: _withCtx(() => [_createVNode(_component_van_card, {
          num: "2",
          price: "2.00",
          desc: "描述信息",
          title: "商品标题",
          class: "goods-card",
          thumb: "https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg"
        })]),
        _: 1
      })]);
    };
  }
};